<template>
  <div class="mx-auto" style="width: fit-content">
    <div
      class="row d-flex justify-content-center p-2"
      style="max-width: 370px; height: 100vh"
    >
      <div class="col-lg-12 row align-self-end">
        <div class="col-lg-12">
          <img
            alt="blend box logo"
            class="img-fluid logo"
            src="blendBoxLogo.svg"
            width="128px"
          />
        </div>
        <div class="col-lg-12">
          <img
            alt="blend box logo"
            class="img-fluid logo"
            src="../../assets/images/login/loginImg.png"
            width="352px"
          />
        </div>
      </div>

      <div class="col-lg-12 align-self-end">
        <label class="form-control-label">Enter your number</label>
        <input
          v-model="phone"
          class="form-control game_input input-btn"
          placeholder="+964"
          type="text"
          @input="valueChanged"
        />
      </div>

      <div class="col-lg-12 login-btm login-button">
        <button
          id="sub_btn"
          :class="{ game_btn: true, activeBtn: isChanged }"
          :disabled="btnDisabled"
          class="w-100 d-block main-button-orange"
          @click="generateScript()"
        >
          {{ btnDisabled ? "Loading ..." : "Subscribe" }}
        </button>
      </div>

      <div
        class="col-lg-12 text-center align-self-start"
        style="font-size: 12px"
      >
        مرحبًا بك في خدمة BlendBox، مصدرك المفضل للتعاليم الإسلامية وأدلة
        اللياقة البدنية والمحتوى النسائي والكوميديا والألعاب. اكتشف واستمتع
        بمحتوى متنوع في مكان واحد! اشترك الآن عن طريق إدخال رقمك، وسيحصل
        المستخدمون على 5 نقاط مقابل الوصول إلى المحتوى يوميًا 300د.ع.
      </div>
    </div>
  </div>
</template>

<script>
import AlertService from "@/services/errors"
import ApiService from "@/services/api"
import { v4 as uuidv4 } from "uuid"

export default {
  data() {
    return {
      prefixPHone: "+964",
      phone: "+964",
      isChanged: false,
      btnDisabled: false,
      isLoading: false
    }
  },
  watch: {
    phone(val) {
      if (val === "") {
        this.isChanged = false
      }
    }
  },
  methods: {
    valueChanged() {
      this.isChanged = true

      const countryCode = "+964"
      if (!this.phone.startsWith(countryCode)) {
        // If the input doesn't start with '+964', add it back
        this.phone = countryCode + this.phone.substring(countryCode.length)
      }
      if (this.phone.length >= 13) {
        this.phone = this.phone.slice(0, 14)
      }
    },

    async generateScript() {
      this.btnDisabled = true
      var phoneNumber = "964" + this.phone.replaceAll("+964", "")
      if (phoneNumber.length < 7) {
        AlertService.errorMessage("Invalid Phone")
        this.btnDisabled = false
        return
      }
      var uuid = "BlendBox-App" + uuidv4()
      var timestamp = this.getTimeStamp()
      this.$store.commit("setPhoneNumber", phoneNumber)
      this.$store.commit("setTi", uuid)
      this.$store.commit("setTS", timestamp)
      const response = await ApiService.generateScript(uuid, timestamp)
      if (response && response.status === 200) {
        var s = response.data.s
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.text = s
        document.head.appendChild(script)
        const ev = new Event("DCBProtectRun")
        document.dispatchEvent(ev)
        await this.sendSMS()
      } else {
        this.btnDisabled = false
        this.isLoading = false
      }
    },
    async sendSMS() {
      const phoneNumber = this.$store.state.phoneNumber
      const ti = this.$store.state.ti
      const response = await ApiService.login(phoneNumber, ti)
      this.isLoading = false
      if (response && response.status === 200) {
        this.$router.push("/verify")
      } else {
        this.btnDisabled = false
        this.errorMessage("Error")
      }
    },
    getTimeStamp() {
      return parseInt(new Date().getTime() / 1000)
    }
  },
  beforeDestroy() {
    // Clean up by removing the window resize event listener
    window.removeEventListener("resize", this.checkScreenSize)
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem("lang") ?? "en"
  }
}
</script>

<style scoped>
input:focus {
  outline: none;
  box-shadow: 0 0 0;
}

input[type="text"] {
  background-color: var(--iq-body-bg);
  border: none;
  border-bottom: 1px solid var(--iq-primary-orange);
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  margin-bottom: 20px;
  padding-left: 0px;

  color: var(--iq-label-grey);
}

.form-control-label {
  font-size: 14px;
  color: var(--iq-label-grey);
  font-weight: bold;
  letter-spacing: 1px;
}

.login-button {
  border-radius: 10px;
}
</style>
